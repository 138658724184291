<template>
  <div>
    <div v-if="isMobile===false" class="pdfBox">
      <div class="allTitle" v-if="languageSelect==='zh'">
        <div class="left">
          <div class="title">
            {{ PDFList.title }}
          </div>
          <div class="cancel" v-if="showCancel" @click="Cancel(PDFList.id)">
            取消订阅
          </div>
        </div>
        <div class="more">
          <router-link
              v-if="showCancel"
              :to="{
            path: '/Science?id=2',
            query: { TypeId: PDFList.secondaryType },
          }"
          >更多 >></router-link
          >
          <router-link v-else to="/Science?id=2">更多 >></router-link>
        </div>
      </div>
      <div class="allTitle" v-if="languageSelect==='en'">
        <div class="left">
          <div class="title">
            {{ PDFList.titleEn }}
          </div>
          <div class="cancel" v-if="showCancel" @click="Cancel(PDFList.id)">
            Unsubscribe
          </div>
        </div>
        <div class="more">
          <router-link
              v-if="showCancel"
              :to="{
            path: '/Science?id=2',
            query: { TypeId: PDFList.secondaryType },
          }"
          >More >></router-link
          >
          <router-link v-else to="/Science?id=2">More >></router-link>
        </div>
      </div>
      <div  v-if="PDFList.list.length"  >
        <div class="list" v-if="languageSelect==='zh'">
          <div class="item" v-for="(item, index) in PDFList.list" :key="index">
            <div class="image" @click="ToLook(item)">
              <img :src="baseURL + item.img" class="amplify" alt="" />
            </div>
            <div class="right"  >
              <div class="title" @click="ToLook(item)">
                <el-tooltip
                    placement="top"
                    :disabled="isTooltipTitle"
                    effect="light"
                >
                  <div slot="content" style="max-width: 200px">
                    {{ item.title }}
                  </div>
                  <div class="oneLine" @mouseenter="visibilityChangeTitle($event)">
                    {{ item.title }}
                  </div>
                </el-tooltip>
              </div>
              <div class="editor">
                <img loading="lazy" src="@/assets/editor.png" alt="" />
                作者：
                <el-tooltip
                    placement="bottom"
                    :disabled="isTooltipEditor"
                    effect="light"
                >
                  <div slot="content" style="max-width: 200px">
                    {{ item.author }}
                  </div>
                  <span
                      class="oneLine"
                      @mouseenter="visibilityChangeEditor($event)"
                  >
                {{ item.author }}
              </span>
                </el-tooltip>
              </div>
              <div class="time">
                <img loading="lazy" src="@/assets/time.png" alt="" />
                时间：<span>{{ item.time }}</span>
              </div>
              <div class="typeList">
            <span
                v-for="(items, indexs) in item.label.split(',')"
                :key="indexs"
            >
              {{ items }}
            </span>
              </div>
              <div class="abstract" v-if="item.digest">
                <img loading="lazy" src="@/assets/abstract.png" alt="" />
                摘要：
                <el-tooltip placement="bottom" :disabled="isTooltip" effect="light">
                  <div slot="content" style="max-width: 200px">
                    {{ item.digest }}
                  </div>
                  <span class="oneLine" @mouseenter="visibilityChange($event)">
                {{ item.digest }}
              </span>
                </el-tooltip>
              </div>
              <div
                  class="downLoad"
                  @click="download(item, index)"
                  :id="'d' + index"
              >
                <img loading="lazy" src="@/assets/pdfIcon.png" class="pdfIcon" alt="" />
                <img loading="lazy" src="@/assets/pdfIconHover.png" class="pdfIconHover" alt="" />
                下载PDF
              </div>
            </div>
          </div>
        </div>
        <div class="list" v-if="languageSelect==='en'">
          <div class="item" v-for="(item, index) in PDFList.list" :key="index">
            <div class="image" @click="ToLook(item)">
              <img :src="baseURL + item.img" class="amplify" alt="" />
            </div>
            <div class="right"  >
              <div class="title" @click="ToLook(item)">
                <el-tooltip
                    placement="top"
                    :disabled="isTooltipTitle"
                    effect="light"
                >
                  <div slot="content" style="max-width: 200px">
                    {{ item.title }}
                  </div>
                  <div class="oneLine" @mouseenter="visibilityChangeTitle($event)">
                    {{ item.title }}
                  </div>
                </el-tooltip>
              </div>
              <div class="editor">
                <img loading="lazy" src="@/assets/editor.png" alt="" />
                Author：
                <el-tooltip
                    placement="bottom"
                    :disabled="isTooltipEditor"
                    effect="light"
                >
                  <div slot="content" style="max-width: 200px">
                    {{ item.author }}
                  </div>
                  <span
                      class="oneLine"
                      @mouseenter="visibilityChangeEditor($event)"
                  >
                {{ item.author }}
              </span>
                </el-tooltip>
              </div>
              <div class="time">
                <img loading="lazy" src="@/assets/time.png" alt="" />
                Time：<span>{{ item.time }}</span>
              </div>
              <div class="typeList">
            <span
                v-for="(items, indexs) in item.label.split(',')"
                :key="indexs"
            >
              {{ items }}
            </span>
              </div>
              <div class="abstract" v-if="item.digest">
                <img loading="lazy" src="@/assets/abstract.png" alt="" />
                Abstract：
                <el-tooltip placement="bottom" :disabled="isTooltip" effect="light">
                  <div slot="content" style="max-width: 200px">
                    {{ item.digest }}
                  </div>
                  <span class="oneLine" @mouseenter="visibilityChange($event)">
                {{ item.digest }}
              </span>
                </el-tooltip>
              </div>
              <div
                  class="downLoad"
                  @click="download(item, index)"
                  :id="'d' + index"
              >
                <img loading="lazy" src="@/assets/pdfIcon.png" class="pdfIcon" alt="" />
                <img loading="lazy" src="@/assets/pdfIconHover.png" class="pdfIconHover" alt="" />
                Download PDF
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="none" v-else>
        <div v-if="languageSelect==='zh'">暂无数据</div>
        <div v-if="languageSelect==='en'">No data available</div>
      </div>

      <InfoTip
          :dialogVisibleInfo="dialogVisibleInfo"
          @hideDialog="hideDialog"
      ></InfoTip>
    </div>
    <div v-else class="pdfBox1">
      <div class="allTitle" v-if="languageSelect==='zh'">
        <div class="left">
          <div class="title">
            {{ PDFList.title }}
          </div>
          <div class="cancel" v-if="showCancel" @click="Cancel(PDFList.id)">
            取消订阅
          </div>
        </div>
        <div class="more">
          <router-link
              v-if="showCancel"
              :to="{
            path: '/Science?id=2',
            query: { TypeId: PDFList.secondaryType },
          }"
          >更多 >></router-link
          >
          <router-link v-else to="/Science?id=2">更多 >></router-link>
        </div>
      </div>
      <div class="allTitle" v-if="languageSelect==='en'">
        <div class="left">
          <div class="title">
            {{ PDFList.titleEn }}
          </div>
          <div class="cancel" v-if="showCancel" @click="Cancel(PDFList.id)">
            Unsubscribe
          </div>
        </div>
        <div class="more">
          <router-link
              v-if="showCancel"
              :to="{
            path: '/Science?id=2',
            query: { TypeId: PDFList.secondaryType },
          }"
          >More >></router-link
          >
          <router-link v-else to="/Science?id=2">More >></router-link>
        </div>
      </div>
      <div  v-if="PDFList.list.length"  >
        <div class="list" v-if="languageSelect==='zh'">
          <div class="item" v-for="(item, index) in PDFList.list" :key="index">
            <div class="image" @click="ToLook(item)">
              <img :src="baseURL + item.img" class="amplify" alt="" />
            </div>
            <div class="right"  >
              <div class="title" @click="ToLook(item)">
                <el-tooltip
                    placement="top"
                    :disabled="isTooltipTitle"
                    effect="light"
                >
                  <div slot="content" style="max-width: 200px">
                    {{ item.title }}
                  </div>
                  <div class="oneLine" @mouseenter="visibilityChangeTitle($event)">
                    {{ item.title }}
                  </div>
                </el-tooltip>
              </div>
              <div class="editor">
                <img loading="lazy" src="@/assets/editor.png" alt="" />
                作者：
                <el-tooltip
                    placement="bottom"
                    :disabled="isTooltipEditor"
                    effect="light"
                >
                  <div slot="content" style="max-width: 200px">
                    {{ item.author }}
                  </div>
                  <span
                      class="oneLine"
                      @mouseenter="visibilityChangeEditor($event)"
                  >
                {{ item.author }}
              </span>
                </el-tooltip>
              </div>
              <div class="time">
                <img loading="lazy" src="@/assets/time.png" alt="" />
                时间：<span>{{ item.time }}</span>
              </div>
              <div class="typeList">
            <span
                v-for="(items, indexs) in item.label.split(',')"
                :key="indexs"
            >
              {{ items }}
            </span>
              </div>
              <div class="abstract" v-if="item.digest">
                <img loading="lazy" src="@/assets/abstract.png" alt="" />
                摘要：
                <el-tooltip placement="bottom" :disabled="isTooltip" effect="light">
                  <div slot="content" style="max-width: 200px">
                    {{ item.digest }}
                  </div>
                  <span class="oneLine" @mouseenter="visibilityChange($event)">
                {{ item.digest }}
              </span>
                </el-tooltip>
              </div>
              <div
                  class="downLoad"
                  @click="download(item, index)"
                  :id="'d' + index"
              >
                <img loading="lazy" src="@/assets/pdfIcon.png" class="pdfIcon" alt="" />
                <img loading="lazy" src="@/assets/pdfIconHover.png" class="pdfIconHover" alt="" />
                下载PDF
              </div>
            </div>
          </div>
        </div>
        <div class="list" v-if="languageSelect==='en'">
          <div class="item" v-for="(item, index) in PDFList.list" :key="index">
            <div class="image" @click="ToLook(item)">
              <img :src="baseURL + item.img" class="amplify" alt="" />
            </div>
            <div class="right"  >
              <div class="title" @click="ToLook(item)">
                <el-tooltip
                    placement="top"
                    :disabled="isTooltipTitle"
                    effect="light"
                >
                  <div slot="content" style="max-width: 200px">
                    {{ item.title }}
                  </div>
                  <div class="oneLine" @mouseenter="visibilityChangeTitle($event)">
                    {{ item.title }}
                  </div>
                </el-tooltip>
              </div>
              <div class="editor">
                <img loading="lazy" src="@/assets/editor.png" alt="" />
                Author：
                <el-tooltip
                    placement="bottom"
                    :disabled="isTooltipEditor"
                    effect="light"
                >
                  <div slot="content" style="max-width: 200px">
                    {{ item.author }}
                  </div>
                  <span
                      class="oneLine"
                      @mouseenter="visibilityChangeEditor($event)"
                  >
                {{ item.author }}
              </span>
                </el-tooltip>
              </div>
              <div class="time">
                <img loading="lazy" src="@/assets/time.png" alt="" />
                Time：<span>{{ item.time }}</span>
              </div>
              <div class="typeList">
            <span
                v-for="(items, indexs) in item.label.split(',')"
                :key="indexs"
            >
              {{ items }}
            </span>
              </div>
              <div class="abstract" v-if="item.digest">
                <img loading="lazy" src="@/assets/abstract.png" alt="" />
                Abstract：
                <el-tooltip placement="bottom" :disabled="isTooltip" effect="light">
                  <div slot="content" style="max-width: 200px">
                    {{ item.digest }}
                  </div>
                  <span class="oneLine" @mouseenter="visibilityChange($event)">
                {{ item.digest }}
              </span>
                </el-tooltip>
              </div>
              <div
                  class="downLoad"
                  @click="download(item, index)"
                  :id="'d' + index"
              >
                <img loading="lazy" src="@/assets/pdfIcon.png" class="pdfIcon" alt="" />
                <img loading="lazy" src="@/assets/pdfIconHover.png" class="pdfIconHover" alt="" />
                Download PDF
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="none" v-else>
        <div v-if="languageSelect==='zh'">暂无数据</div>
        <div v-if="languageSelect==='en'">No data available</div>
      </div>

      <InfoTip
          :dialogVisibleInfo="dialogVisibleInfo"
          @hideDialog="hideDialog"
      ></InfoTip>
    </div>
  </div>

</template>
<script>
import InfoTip from "@/components/InfoTip.vue";
// import { getFile } from "@/utils/download";
export default {
  name: "PDFList",
  components: {
    InfoTip,
  },
  data() {
    return {
      isMobile:false,
      languageSelect:'',
      baseURL: this.$utils.baseURL,
      isTooltip: false,
      isTooltipEditor: false,
      isTooltipTitle: false,
      dialogVisibleInfo: false,
    };
  },
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }

  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  props: {
    PDFList: {
      type: Object,
      required: true,
    },
    showMore: {
      type: Boolean,
      required: true,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    visibilityChangeTitle() {
      const ev = event.target;
      const evWeight = ev.scrollWidth;
      const contentWeight = ev.clientWidth;
      if (evWeight > contentWeight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isTooltipTitle = false;
      } else {
        // 否则为不溢出
        this.isTooltipTitle = true;
      }
    },
    visibilityChangeEditor() {
      const ev = event.target;
      const evWeight = ev.scrollWidth;
      const contentWeight = ev.clientWidth;
      if (evWeight > contentWeight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isTooltipEditor = false;
      } else {
        // 否则为不溢出
        this.isTooltipEditor = true;
      }
    },
    visibilityChange() {
      const ev = event.target;
      const evWeight = ev.scrollWidth;
      const contentWeight = ev.clientWidth;
      if (evWeight > contentWeight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isTooltip = false;
      } else {
        // 否则为不溢出
        this.isTooltip = true;
      }
    },
    ToLook(data) {
      if (!this.$store.state.access_token) {
        let that = this;
        that.$emit("change", true);
        return false;
      }
      if (this.$store.state.typeInfo == 0) {
        let that = this;
        that.showDialog(true);
        return false;
      }
      let Pdfurl = this.$utils.baseURL + data.filePath;
      this.$router.push({
        path: "/pdfDetail",
        query: {
          filePath: Pdfurl,
        },
      });
      // window.open(Pdfurl);
    },
    download(data) {
      // eslint-disable-next-line no-empty
      if (!this.$store.state.access_token) {
        let that = this;
        that.$emit("change", true);
        return false;
      }
      if (this.$store.state.typeInfo == 0) {
        let that = this;
        that.showDialog(true);
        return false;
      }
      let Pdfurl = this.$utils.baseURL + data.filePath;
      window.location.href = Pdfurl;
      // let Pdfurl = this.$utils.baseURL + data.filePath;
      // let blob = new Blob([Pdfurl], { type: "application/pdf" });
      // let url = window.URL.createObjectURL(blob);
      // let link = document.createElement("a");
      // link.href = url;
      // // link.download = this.getFileName(Pdfurl); // 设置下载的文件名
      // link.click(); // 触发下载操作
    },

    showDialog(type) {
      this.dialogVisibleInfo = type;
    },
    hideDialog() {
      this.dialogVisibleInfo = false;
    },
    getFileName(url) {
      let name = "";
      if (url !== null && url !== "") {
        name = url.substring(url.lastIndexOf("/") + 1);
      } else {
        name = "无";
      }
      return name;
    },
    Cancel(id) {
      this.$emit("change", id);
    },
  },
};
</script>
<style lang="scss" scoped>
.pdfBox {
  background: #fff;
  padding: 30px 20px;
  .allTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cecece;
    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        color: #333333;
        font-weight: 500;
        position: relative;
        padding-bottom: 10px;
      }
      .title::before {
        content: "";
        width: 100%;
        height: 2px;
        background: var(--main-color);
        position: absolute;
        left: 0;
        bottom: -1px;
      }
      .cancel {
        margin-left: 40px;
        font-size: 12px;
        width: 100px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        border-radius: 13px;
        border: 1px solid var(--main-color);
        cursor: pointer;
        color: var(--main-color);
        margin-top: -9px;
      }
      .cancel:hover {
        background: var(--main-color);
        color: white;
      }
    }
    .more {
      font-size: 12px;
      color: #333333;
      padding-bottom: 10px;
      a {
        display: block;
      }
    }
    .more:hover {
      color: var(--main-color);
    }
  }
  .list {
    display: grid;
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    gap: 20px;
    padding-top: 20px;
    .item {
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      padding: 18px;
      display: flex;
      justify-content: space-between;
      .image {
        width: 142px;
        height: 201px;
        box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.05);
        border: 1px solid #c7c7c7;
        border-radius: 13px;
        margin-right: 18px;
        overflow: hidden;
        cursor: pointer;
      }
      .right {
        flex: 1;
        min-width: 0;

        > div {
          margin-bottom: 10px;
          font-size: 14px;
          color: #666666;
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
          span {
            flex: 1;
          }
        }
        .title {
          font-size: 18px;
          font-weight: 500;
          line-height: 26px;
          color: #333333;
          display: block;
          cursor: pointer;
        }

        .title:hover {
          color: var(--main-color);
        }
        .editor {
          cursor: pointer;
        }
        .typeList {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          span {
            font-weight: 400;
            line-height: 24px;
            font-size: 12px;
            height: 24px;
            padding: 1px 8px;
            color: var(--main-color);
            background: #faebee;
            border-radius: 2px;
            flex: inherit;
          }
          span:not(:last-child) {
            margin-right: 8px;
          }
        }
        .abstract {
          .el-tooltip {
            cursor: pointer;
          }
        }
        .downLoad {
          width: 128px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #f5f5f5;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-top: 12px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
          }
          img.pdfIconHover {
            display: none;
          }
        }
        .downLoad:hover {
          background: var(--main-color);
          color: #fff;
          img.pdfIcon {
            display: none;
          }
          img.pdfIconHover {
            display: block;
          }
        }
      }
    }
  }
}
.pdfBox1 {
  background: #fff;
  padding: 5px 10px;
  .allTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cecece;
    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
        position: relative;
        padding-bottom: 5px;
      }
      .title::before {
        content: "";
        width: 100%;
        height: 2px;
        background: var(--main-color);
        position: absolute;
        left: 0;
        bottom: -1px;
      }
      .cancel {
        margin-left: 10px;
        font-size: 18px;
        width: 120px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        border-radius: 13px;
        border: 1px solid var(--main-color);
        cursor: pointer;
        color: var(--main-color);
        margin-top: -9px;
      }
      .cancel:hover {
        background: var(--main-color);
        color: white;
      }
    }
    .more {
      font-size: 14px;
      color: #333333;
      padding-bottom: 5px;
      a {
        display: block;
      }
    }
    .more:hover {
      color: var(--main-color);
    }
  }
  .list {
    display: grid;
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: minmax(0, 1fr);
    padding-top: 5px;
    .item {
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      .image {
        width: 108px;
        height: 131px;
        box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.05);
        border: 1px solid #c7c7c7;
        border-radius: 13px;
        margin-top: 5px;
        margin-right: 15px;
        overflow: hidden;
        cursor: pointer;
      }
      .right {
        flex: 1;
        min-width: 0;

        > div {
          margin-bottom: 5px;
          font-size: 14px;
          color: #666666;
          display: flex;
          align-items: center;
          img {
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }
          span {
            flex: 1;
          }
        }
        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 26px;
          color: #333333;
          display: block;
          cursor: pointer;
        }

        .title:hover {
          color: var(--main-color);
        }
        .editor {
          cursor: pointer;
        }
        .typeList {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          span {
            font-weight: 400;
            line-height: 24px;
            font-size: 14px;
            height: 24px;
            padding: 1px 8px;
            color: var(--main-color);
            background: #faebee;
            border-radius: 2px;
            flex: inherit;
          }
          span:not(:last-child) {
            margin-right: 8px;
          }
        }
        .abstract {
          .el-tooltip {
            cursor: pointer;
          }
        }
        .downLoad {
          width: 150px;
          height: 22px;
          line-height: 32px;
          text-align: left;
          background: #f5f5f5;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-top: 5px;
          img {
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }
          img.pdfIconHover {
            display: none;
          }
        }
        .downLoad:hover {
          background: var(--main-color);
          color: #fff;
          img.pdfIcon {
            display: none;
          }
          img.pdfIconHover {
            display: block;
          }
        }
      }
    }
  }
}
.none {
  text-align: center;
  min-height: 200px;
  line-height: 200px;
}
</style>
