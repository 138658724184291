

<template style="height: 100%">
  <div style="display: flex;flex-direction: column;min-height: 100vh">
    <div style="  flex: 1;">
      <div class="MySubscriptions wrapper">
        <Header></Header>
        <div v-if="isMobile===false">
          <el-main class="centerWidth mainContent">
            <template v-if="!user.userId">
              <div class="notLogin" v-if="languageSelect==='zh'">
                <img loading="lazy" src="@/assets/notLogin.png" alt="" />
                登录可查看更多订阅资讯
                <router-link to="/login">立即登录</router-link>
              </div>
              <div class="notLogin" v-if="languageSelect==='en'">
                <img loading="lazy" src="@/assets/notLogin.png" alt=""/>
                Sign in for more subscription information
                <router-link to="/login">Log in</router-link>
              </div>
            </template>
            <template v-else>
              <template v-if="PdfList != null || categoryRecommendList !=null ">
                <template v-if="PdfList.length || categoryRecommendList.length">
                  <div class="flexBox">
                    <NewList
                        v-for="(item, index) in categoryRecommendList"
                        :key="index"
                        :showCancel="true"
                        :newList.sync="item"
                        @change="Delete1"
                    ></NewList>
                  </div>
                  <PDFList
                      class="marginTop"
                      :showMore="true"
                      :showCancel="true"
                      @change="Delete2"
                      v-for="(item, index) in PdfList"
                      :key="index"
                      :PDFList.sync="item"
                  ></PDFList>
                </template>
                <template v-else>
                  <div class="noData" v-if="languageSelect==='zh'">
                    <img loading="lazy" src="@/assets/none.png" alt="" />
                    暂未进行资讯订阅
                  </div>
                  <div class="noData" v-if="languageSelect==='en'">
                    <img loading="lazy" src="@/assets/none.png" alt="" />
                    Information subscription is not currently available
                  </div>
                </template>
              </template>
            </template>
          </el-main>
        </div>
        <div v-else>
          <el-main class="centerWidth1 mainContent1">
            <template v-if="!user.userId">
              <div class="notLogin" v-if="languageSelect==='zh'" style="font-size: 16px">
                <img loading="lazy" src="@/assets/notLogin.png" alt="" />
                登录可查看更多订阅资讯
                <router-link to="/login">立即登录</router-link>
              </div>
              <div class="notLogin" v-if="languageSelect==='en'" style="font-size: 16px">
                <img loading="lazy" src="@/assets/notLogin.png" alt=""/>
                Sign in for more subscription information
                <router-link to="/login">Log in</router-link>
              </div>
            </template>
            <template v-else>
              <template v-if="PdfList != null || categoryRecommendList !=null ">
                <template v-if="PdfList.length || categoryRecommendList.length">
                  <div class="flexBox1">
                    <NewList
                        v-for="(item, index) in categoryRecommendList"
                        :key="index"
                        :showCancel="true"
                        :newList.sync="item"
                        @change="Delete1"
                    ></NewList>
                  </div>
                  <PDFList
                      class="marginTop1"
                      :showMore="true"
                      :showCancel="true"
                      @change="Delete2"
                      v-for="(item, index) in PdfList"
                      :key="index"
                      :PDFList.sync="item"
                  ></PDFList>
                </template>
                <template v-else>
                  <div class="noData" v-if="languageSelect==='zh'">
                    <img loading="lazy" src="@/assets/none.png" alt="" />
                    暂未进行资讯订阅
                  </div>
                  <div class="noData" v-if="languageSelect==='en'">
                    <img loading="lazy" src="@/assets/none.png" alt="" />
                    Information subscription is not currently available
                  </div>
                </template>
              </template>
            </template>
          </el-main>
        </div>
        <AiPop @change="showDialog"></AiPop>
        <CancleTip
            :dialogVisible="cancleVisible"
            @hide="hide"
            @confirmDialog="confirmDialog"
        ></CancleTip>
        <BottomBanner v-if="isMobile===true">123</BottomBanner>
      </div>
    </div>
    <div style="margin-top: auto">
      <Footer v-if="isMobile===false"></Footer>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import PDFList from "@/components/PDFList.vue";
import NewList from "@/components/NewList.vue";
import Footer from "@/components/Footer.vue";
import { getNews, getReport, deleteReport } from "@/api/user";
import CancleTip from "@/components/CancleTip.vue";
import AiPop from "@/components/AiPop.vue";
import BottomBanner from "@/components/BottomBanner.vue";
import { shareUrl } from "@/utils/vxshare.js";
export default {
  name: "MySubscriptions",
  components: {BottomBanner, AiPop, Header, Footer, NewList, PDFList, CancleTip },
  data() {
    return {
      dialogVisible:false,
      isMobile:false,
      user: {
        userId: "",
        name: "",
      },
      languageSelect:'',
      cancleVisible: false,
      categoryRecommendList: null,
      PdfList: null,
      clickId: null,
      type: 1,
      titleEn:''
    };
  },
  watch: {
    "$store.state.user"(newValue) {
      this.user = newValue;
    },
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  computed: {},
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }
    if (this.$store.state.access_token) {
      this.user = this.$store.state.user;
      this.getNews();
      this.getReport();
    }

    let url = window.location.host
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气人才',
        text:'全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！',
      }
      shareUrl(shareData);
    }, 1000);

  },
  methods: {
    showDialog(type) {
      this.dialogVisible = type;
    },
    async getNews() {
      try {
        const res = await getNews({
          limit: 3,
          userId: this.user.userId,
        });

        this.categoryRecommendList = res.data || [];
        if (this.PdfList == null) this.PdfList = [];

        const promises = this.categoryRecommendList.map(async (item) => {
          item.title = item.secondaryName;

          try {
            const response = await this.$axios.get("/systemcategory/optionSelect", {
              params: {
                level: 2,
                categoryId: item.secondaryType,
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
              },
            });

            item.titleEn = response.data.data[0].titleEn;
            this.$set(this, 'titleEn', response.data.data.enTitle);
          } catch (error) {
            console.log(error);
          }
        });

        // 等待所有异步请求完成
        await Promise.all(promises);

        // 所有异步请求都成功完成后进行后续操作
        for (const item of this.categoryRecommendList) {
          if (this.languageSelect === 'en') {
            item.title = item.titleEn;
          }
          console.log(item);
        }

        // 手动强制更新
        this.$forceUpdate();
      } catch (error) {
        console.log(error);
      }
    },

    // 获取二级分类yingwen
    async classfy(id) {
      try {
        const response = await this.$axios.get("/systemcategory/optionSelect", {
          params: {
            level: 2,
            categoryId: id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        });

        this.titleEn = response.data.data[0].titleEn;

        return this.titleEn; // 返回获取到的 titleEn
      } catch (error) {
        console.log(error);
        throw error; // 抛出错误
      }
    },

    getReport() {
      getReport({
        limit: 6,
        userId: this.user.userId,
      })
        .then((res) => {
          this.PdfList = res.data || [];
          if (this.categoryRecommendList == null) this.categoryRecommendList = []
          console.log(this.PdfList,'111')
          this.PdfList.forEach((item) => {
            item.title = item.secondaryName;
            if(item.secondaryName==="中文期刊"){
              item.titleEn="Chinese Journals"
            }else {
              item.titleEn="English Journals"
            }
            item.list = item.reportList;
          });
        })
        .catch(() => {});
    },
    Delete1(id) {
      this.clickId = id;
      this.cancleVisible = true;
      this.type = 1;
    },
    Delete2(id) {
      console.log("11111");
      this.clickId = id;
      this.cancleVisible = true;
      this.type = 2;
    },
    hide() {
      this.cancleVisible = false;
    },
    confirmDialog() {
      deleteReport(this.clickId).then(() => {
        this.cancleVisible = false;
        //跳转页面或执行方法
        if (this.type == 1) {
          let index = this.categoryRecommendList.findIndex(
            (item) => item.id === this.clickId
          );
          this.categoryRecommendList.splice(index, 1);
        } else {
          let index = this.PdfList.findIndex(
            (item) => item.id === this.clickId
          );
          this.PdfList.splice(index, 1);
        }
        this.clickId = null;
        this.$message({
          message: "取消订阅成功！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {},
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.flexBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}
.marginTop {
  margin-top: 30px;
}
.marginTop1 {
  margin-top: 10px;
}
.centerWidth1 {
  width: 98%;
  margin: 0 auto 60px auto;
}.mainContent1 {
   flex: 1;
 }
.flexBox1 {
  margin-top: 10px;
}
</style>
