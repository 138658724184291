<template>
  <div>
    <div v-if="isMobile===false" class="newBoxList">
      <div class="allTitle">
        <div class="left" v-if="languageSelect==='zh'">
          <div class="title" >
            {{ newList.title }}
          </div>
          <div class="cancel" v-if="showCancel" @click="Cancel(newList.id)">
            取消订阅
          </div>
        </div>
        <div class="left" v-if="languageSelect==='en'">
          <div class="title" >
            {{ newList.titleEn }}
          </div>
          <div class="cancel" v-if="showCancel" @click="Cancel(newList.id)">
            Unsubscribe
          </div>
        </div>
        <div class="more" v-if="languageSelect==='zh'" >
          <router-link
              v-if="showCancel"
              :to="{ path: newList.url || '', query: { TypeId: newList.secondaryType },
          }"
          >更多 >></router-link
          >
          <router-link v-else :to="newList.url || ''">更多 >></router-link>
        </div>
        <div class="more" v-if="languageSelect==='en'" >
          <router-link
              v-if="showCancel"
              :to="{ path: newList.url || '', query: { TypeId: newList.secondaryType },
          }"
          >More >></router-link
          >
          <router-link v-else :to="newList.url || ''">More >></router-link>
        </div>
      </div>
      <div class="list">
        <div
            class="item"
            v-for="(item, index) in newList.newsList"
            :key="index"
            @click="toDetail(item)"
        >
          <div class="image" v-if="index == 0">
            <img
                :src="item.img ? baseURL + item.img : ''"
                class="amplify"
                alt=""
            />
          </div>
          <div class="content" v-if="languageSelect==='zh'">
            <div class="title">
              <div class="oneLine">
                {{ item.title }}
              </div>
            </div>
            <div class="textBox">
              <div
                  :class="[index > 0 ? 'two' : 'three', 'text']"
                  v-html="item.contentText"
                  :title1="item.contentText"
              ></div>
              <span class="">查看详情>></span>
            </div>
          </div>
          <div class="content" v-if="languageSelect==='en'">
            <div class="title">
              <div class="oneLine">
                {{ titleEn}}
              </div>
            </div>
            <div class="textBox">
              <div
                  :class="[index > 0 ? 'two' : 'three', 'text']"
                  v-html="contentEn"
                  :title1="contentEn"
              ></div>
              <span class="">details>></span>
            </div>
          </div>
        </div>
      </div>
      <InfoTip
          :dialogVisibleInfo="dialogVisibleInfo"
          @hideDialog="hideDialog"
      ></InfoTip>
    </div>
    <div v-if="isMobile===true" class="newBoxList1">
      <div class="allTitle">
        <div class="left" v-if="languageSelect==='zh'">
          <div class="title" >
            {{ newList.title }}
          </div>
          <div class="cancel" v-if="showCancel" @click="Cancel(newList.id)">
            取消订阅
          </div>
        </div>
        <div class="left" v-if="languageSelect==='en'">
          <div class="title" >
            {{ newList.titleEn }}
          </div>
          <div class="cancel" v-if="showCancel" @click="Cancel(newList.id)">
            Unsubscribe
          </div>
        </div>
        <div class="more" v-if="languageSelect==='zh'" >
          <router-link
              v-if="showCancel"
              :to="{ path: newList.url || '', query: { TypeId: newList.secondaryType },
          }"
          >更多 >></router-link
          >
          <router-link v-else :to="newList.url || ''">更多 >></router-link>
        </div>
        <div class="more" v-if="languageSelect==='en'" >
          <router-link
              v-if="showCancel"
              :to="{ path: newList.url || '', query: { TypeId: newList.secondaryType },
          }"
          >More >></router-link
          >
          <router-link v-else :to="newList.url || ''">More >></router-link>
        </div>
      </div>
      <div class="list">
        <div
            class="item"
            v-for="(item, index) in newList.newsList"
            :key="index"
            @click="toDetail(item)"
        >
          <div class="image" v-if="index == 0">
            <img
                :src="item.img ? baseURL + item.img : ''"
                class="amplify"
                alt=""
            />
          </div>
          <div class="content" v-if="languageSelect==='zh'">
            <div class="title">
              <div class="oneLine">
                {{ item.title }}
              </div>
            </div>
            <div class="textBox">
              <div
                  :class="[index > 0 ? 'two' : 'three', 'text']"
                  v-html="item.contentText"
                  :title1="item.contentText"
              ></div>
              <span style="font-size: 14px">查看详情>></span>
            </div>
          </div>
          <div class="content" v-if="languageSelect==='en'">
            <div class="title">
              <div class="oneLine">
                {{ titleEn}}
              </div>
            </div>
            <div class="textBox">
              <div
                  :class="[index > 0 ? 'two' : 'three', 'text']"
                  v-html="contentEn"
                  :title1="contentEn"
              ></div>
              <span style="font-size: 14px">details>></span>
            </div>
          </div>
        </div>
      </div>
      <InfoTip
          :dialogVisibleInfo="dialogVisibleInfo"
          @hideDialog="hideDialog"
      ></InfoTip>
    </div>
  </div>

</template>
<script>
import InfoTip from "@/components/InfoTip.vue";
export default {
  name: "newList",
  components: {
    InfoTip,
  },
  props: {
    newList: {
      type: Object,
      required: true,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobile:true,
      languageSelect:'',
      baseURL: this.$utils.baseURL,
      num: 86,
      titleEn:'',
      contentEn:'',
      dialogVisibleInfo: false,
    };
  },
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }
    this.newList.newsList.forEach((item) => {
      this.enTrans(item.id)
      if (item.content) item.contentText = this.setText(item.content);
    });
  },
  methods: {
    async enTrans(id){
      var vm=this
      vm.$axios.post("/systemenes/trans", {
        // params: {
        newsId:id,
        // },
        headers: {
          Authorization: vm.token,
        },
      })
          .then((res) => {
            // console.log(res.data.data.enTitle)
            vm.titleEn=res.data.data.enTitle
            vm.contentEn=res.data.data.enContent
          })
          .catch((error) => {
            console.log(error);
          });
    },
    toDetail(item) {
      console.log(this.newList.isView);
      // eslint-disable-next-line no-empty
      if (!this.$store.state.access_token && this.newList.isView != "1") {
        let that = this;
        that.$emit("change", true);
        return false;
      }
      if (this.$store.state.typeInfo == 0 && this.newList.isWhole != "1") {
        let that = this;
        that.showDialog(true);
        return false;
      }
      this.$router.push({
        path: "/articalDetail",
        query: {
          Typeid: item.primaryTypeid,
          newId: item.id,
        },
      });
    },
    Cancel(id) {
      this.$emit("change", id);
    },
    showDialog(type) {
      this.dialogVisibleInfo = type;
    },
    hideDialog() {
      this.dialogVisibleInfo = false;
    },
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
};
</script>
<style lang="scss" scoped>
.newBoxList {
  width: 100%;
  background: #fff;
  padding: 30px 20px;
  .allTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cecece;
    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        color: #333333;
        font-weight: 500;
        position: relative;
        padding-bottom: 10px;
      }
      .title::before {
        content: "";
        width: 100%;
        height: 2px;
        background: var(--main-color);
        position: absolute;
        left: 0;
        bottom: -1px;
      }
      .cancel {
        margin-left: 40px;
        font-size: 12px;
        width: 100px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        border-radius: 13px;
        border: 1px solid var(--main-color);
        cursor: pointer;
        color: var(--main-color);
        margin-top: -9px;
      }
      .cancel:hover {
        background: var(--main-color);
        color: white;
      }
    }

    .more {
      font-size: 12px;
      color: #333333;
      padding-bottom: 10px;
      a {
        display: block;
      }
    }
    .more:hover {
      color: var(--main-color);
    }
  }

  .list {
    .item {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      cursor: pointer;
      .image {
        width: 171px;
        height: 120px;
        margin-right: 20px;
        overflow: hidden;
      }
      .content {
        flex: 1;
        width: 0;
        .title {
          font-size: 18px;
          color: #333333;
          font-weight: 500;
          line-height: 26px;
          border-bottom: 1px dotted #cecece;
          padding-bottom: 10px;
          display: flex;
          align-items: center;
          margin-bottom: 6px;
        }

        .title:hover {
          color: var(--main-color);
        }
        .textBox {
          position: relative;
          // max-width: 100%;
          // width: fit-content;
          padding-right: 5em;
          .text {
            color: #666666;
            font-size: 14px;
            font-weight: 400;
            line-height: 26px;
            min-height: 26px;
          }
          .text:hover::before,
          .text:hover::after {
            color: var(--main-color);
          }
          .two {
            max-height: 52px;
            word-wrap: break-word;
            word-break: break-all;
            background: #ffffff;
            color: #ffffff;
            overflow: hidden;
            line-height: 26px;
          }
          .three {
            max-height: 78px;
            word-wrap: break-word;
            word-break: break-all;
            background: #ffffff;
            color: #ffffff;
            overflow: hidden;
            line-height: 26px;
          }
          .two::before,
          .three::before,
          .two::after,
          .three::after {
            content: attr(title1);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            color: #434343;
            line-height: 26px;
          }
          .two::before {
            display: block;
            overflow: hidden;
            z-index: 1;
            max-height: 26px;
            height: 100%;
            background: #ffffff;
          }
          .two::after {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 52px;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            box-sizing: border-box;
            text-indent: -5em;
            padding-right: 5em;
          }
          .three::before {
            display: block;
            overflow: hidden;
            z-index: 1;
            max-height: 52px;
            height: 100%;
            background: #ffffff;
          }
          .three::after {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 78px;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            box-sizing: border-box;
            text-indent: -10em;
            padding-right: 5em;
          }
          span {
            // background: #fff;
            position: absolute;
            bottom: 0;
            right: 0;
            color: #333333;
            font-size: 12px;
            line-height: 26px;
            padding-left: 10px;
            z-index: 1;
          }
          span:hover {
            color: var(--main-color);
          }
        }
      }
    }
    .item:not(:first-child) {
      .title::before {
        content: " ";
        width: 6px;
        height: 6px;
        border-radius: 3px;
        display: inline-block;
        background: var(--main-color);
        margin-right: 5px;
      }
    }
  }
}
.newBoxList1 {
  width: 100%;
  margin-top: 10px;
  background: #fff;
  padding: 10px 5px;
  .allTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cecece;
    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
        position: relative;
        padding-bottom: 5px;
      }
      .title::before {
        content: "";
        width: 100%;
        height: 2px;
        background: var(--main-color);
        position: absolute;
        left: 0;
        bottom: -1px;
      }
      .cancel {
        margin-left: 10px;
        font-size: 18px;
        width: 120px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        border-radius: 13px;
        border: 1px solid var(--main-color);
        cursor: pointer;
        color: var(--main-color);
        margin-top: -9px;
      }
      .cancel:hover {
        background: var(--main-color);
        color: white;
      }
    }

    .more {
      font-size: 14px;
      color: #333333;
      padding-bottom: 5px;
      a {
        display: block;
      }
    }
    .more:hover {
      color: var(--main-color);
    }
  }

  .list {
    .item {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      cursor: pointer;
      .image {
        width: 30%;
        height: 80px;
        margin-right: 20px;
        overflow: hidden;
      }
      .content {
        flex: 1;
        width: 0;
        .title {
          font-size: 16px;
          color: #333333;
          font-weight: 500;
          line-height: 18px;
          border-bottom: 1px dotted #cecece;
          padding-bottom: 2px;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
        }

        .title:hover {
          color: var(--main-color);
        }
        .textBox {
          position: relative;
          // max-width: 100%;
          // width: fit-content;
          padding-right: 5em;
          .text {
            color: #666666;
            font-size: 14px;
            font-weight: 400;
            line-height: 26px;
            min-height: 26px;
          }
          .text:hover::before,
          .text:hover::after {
            color: var(--main-color);
          }
          .two {
            max-height: 52px;
            word-wrap: break-word;
            word-break: break-all;
            background: #ffffff;
            color: #ffffff;
            overflow: hidden;
            line-height: 26px;
          }
          .three {
            max-height: 78px;
            word-wrap: break-word;
            word-break: break-all;
            background: #ffffff;
            color: #ffffff;
            overflow: hidden;
            line-height: 26px;
          }
          .two::before,
          .three::before,
          .two::after,
          .three::after {
            content: attr(title1);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            color: #434343;
            line-height: 26px;
          }
          .two::before {
            display: block;
            overflow: hidden;
            z-index: 1;
            max-height: 26px;
            height: 100%;
            background: #ffffff;
          }
          .two::after {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 52px;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            box-sizing: border-box;
            text-indent: -5em;
            padding-right: 5em;
          }
          .three::before {
            display: block;
            overflow: hidden;
            z-index: 1;
            max-height: 52px;
            height: 100%;
            background: #ffffff;
          }
          .three::after {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height:56px;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            box-sizing: border-box;
            text-indent: -90em;
            padding-right: 5em;
          }
          span {
            // background: #fff;
            position: absolute;
            bottom: 0;
            right: 0;
            color: #333333;
            font-size: 4px;
            line-height: 26px;
            padding-left: 10px;
            z-index: 1;
          }
          span:hover {
            color: var(--main-color);
          }
        }
      }
    }
    .item:not(:first-child) {
      .title::before {
        content: " ";
        width: 6px;
        height: 6px;
        border-radius: 3px;
        display: inline-block;
        background: var(--main-color);
        margin-right: 5px;
      }
    }
  }
}
</style>

